import * as types from 'actions/action-types';

var initialState = {
	company: null,
	company_pending: false,
	company_save_pending: false,
	company_unsubscribe: false,
};

const companyReducer = (state = initialState, action) => {

	switch(action.type) {
		case types.COMPANY + '_PENDING': pending('company'); break;
		case types.COMPANY + '_SAVE_PENDING': save_pending('company'); break;
		case types.COMPANY + '_FULFILLED': fulfilled('company'); break;
		default : break;
	}

	return state;

	function pending(table) {
		state = { ...state,
			[table + '_pending']: true,
		};
	}
	function save_pending(table) {
		state = { ...state,
			[table + '_save_pending']: true,
		};
	}
	function fulfilled(table) {
		state = { ...state,
			[table]: action.data,
			[table + '_pending']: false,
			[table + '_save_pending']: false,
			[table + '_unsubscribe']: (action.unsubscribe) ? action.unsubscribe : state[table + '_unsubscribe'],
		};
	}
}
export default companyReducer;


