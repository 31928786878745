import { createStore, applyMiddleware } from 'redux';
import ReduxThunk from 'redux-thunk';
import { createLogger } from 'redux-logger'
import promise from "redux-promise-middleware";
import reducers from './reducers';

var store;


if (process.env.NODE_ENV === 'development') {

	const loggerMiddleware = createLogger({
		// predicate: (getState, action) => action.type !== 'UPDATE_FORM_FIELD',
		collapsed: true,
	});

	store = createStore(
		reducers,
		applyMiddleware(
			promise,
			ReduxThunk, // lets us dispatch() functions
			loggerMiddleware // neat middleware that logs actions
		)
	);

} else {

	store = createStore(
		reducers,
		applyMiddleware(
			promise,
			ReduxThunk // lets us dispatch() functions
		)
	);
}

export default store;