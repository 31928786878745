import * as types from 'actions/action-types';

var initialState = {
    quickbooks: null,
    quickbooks_saved: null, // this allows search page to trigger search update
    quickbooks_map_coordinates: null,
    quickbooks_pending: false,
    quickbooks_save_pending: false,
    quickbooks_unsubscribe: false,

    quickbooks_sync_save_pending: false,

    quickbooks_import_pending: false,
};

const quickbooksReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.QUICKBOOKS + '_PENDING': pending('quickbooks'); break;
        case types.QUICKBOOKS + '_FULFILLED': fulfilled('quickbooks'); break;
        case types.QUICKBOOKS + '_SAVE_PENDING': save_pending('quickbooks'); break;
        case types.QUICKBOOKS + '_SAVE_FULFILLED': save_fulfilled('quickbooks'); break;

        case types.QUICKBOOKS_SYNC + '_SAVE_PENDING': save_pending('quickbooks_sync'); break;
        case types.QUICKBOOKS_SYNC + '_SAVE_FULFILLED': save_fulfilled('quickbooks_sync'); break;

        case types.QUICKBOOKS_IMPORT + '_PENDING': pending('quickbooks_import'); break;
        case types.QUICKBOOKS_IMPORT + '_FULFILLED': import_fulfilled('quickbooks_import'); break;

        default: break;
    }

    return state;

    function pending(table) {
        state = {
            ...state,
            [table + '_pending']: true,
        };
    }
    function save_pending(table) {
        state = {
            ...state,
            [table + '_save_pending']: true,
        };
    }
    function save_fulfilled(table) {
        state = {
            ...state,
            [table + '_saved']: action.data,
            [table + '_save_pending']: false,
        };
    }
    function fulfilled(table) {
        state = {
            ...state,
            [table]: action?.data,
            [table + '_pending']: false,
            [table + '_save_pending']: false,
            [table + '_unsubscribe']: (action.unsubscribe) ? action.unsubscribe : state[table + '_unsubscribe'],
        };
    }
    function import_fulfilled(table) {
        state = {
            ...state,
            [table + '_pending']: false,
        };
    }
};
export default quickbooksReducer;


