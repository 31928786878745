
import * as types from 'actions/action-types';

var initialState = {
    conversations: null,
    conversations_pending: false,
    conversations_save_pending: false,
    conversations_unsubscribe: false,
};

const ChatReducer = (state = initialState, action) => {

    switch (action.type) {
        case types.CONVERSATIONS + '_PENDING': pending('conversations'); break;
        case types.CONVERSATIONS + '_SAVE_PENDING': save_pending('conversations'); break;
        case types.CONVERSATIONS + '_SAVE_FULFILLED': save_fulfilled('conversations'); break;
        case types.CONVERSATIONS + '_FULFILLED': fulfilled('conversations'); break;
        case types.CONVERSATIONS + '_CLEAR': clear('conversations', {}); break;

        case types.MESSAGES + '_PENDING': pending('messages'); break;
        case types.MESSAGES + '_SAVE_PENDING': save_pending('messages'); break;
        case types.MESSAGES + '_SAVE_FULFILLED': save_fulfilled('messages'); break;
        case types.MESSAGES + '_FULFILLED': fulfilled('messages'); break;
        case types.MESSAGES + '_CLEAR': clear('messages', {}); break;

        case types.UNREAD_MESSAGES + '_PENDING': pending('unread_messages'); break;
        case types.UNREAD_MESSAGES + '_SAVE_PENDING': save_pending('unread_messages'); break;
        case types.UNREAD_MESSAGES + '_SAVE_FULFILLED': save_fulfilled('unread_messages'); break;
        case types.UNREAD_MESSAGES + '_FULFILLED': fulfilled('unread_messages'); break;
        case types.UNREAD_MESSAGES + '_CLEAR': clear('unread_messages', {}); break;

        default: break;
    }

    return state;

    function pending(table) {
        state = {
            ...state,
            [table + '_pending']: true,
        };
    }
    function save_pending(table) {
        state = {
            ...state,
            [table + '_save_pending']: true,
        };
    }
    function save_fulfilled(table) {
        state = {
            ...state,
            [table + '_saved']: action.data,
            [table + '_save_pending']: false,
        };
    }
    function fulfilled(table) {
        state = {
            ...state,
            [table]: action.data,
            [table + '_pending']: false,
            [table + '_unsubscribe']: (action.unsubscribe) ? action.unsubscribe : state[table + '_unsubscribe'],
        };
    }
    function clear(table, value) {
        state = {
            ...state,
            [table]: value,
        };
    }
};
export default ChatReducer;


