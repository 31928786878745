import React, { useEffect, useState } from 'react';
import * as actions_admin from 'actions/admin-actions';
import { Ibox } from 'enspire-manager-framework';
import { useErrorBoundary } from "react-error-boundary";
import { useDispatch, useSelector } from 'react-redux';
import { ROLES } from 'components/common/constants';
import { useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min';

export default function ErrorFallback({ error, resetErrorBoundary, className, style }) {

	const { resetBoundary } = useErrorBoundary();
	const location = useLocation();
	const dispatch = useDispatch();
	const params = useParams();

	const user = useSelector((store) => store.users.user);
	const admin = useSelector((store) => store.admin);

	const [isOpen, setIsOpen] = useState(false);
	const [errorLogged, setErrorLogged] = useState(false);

	const handle = params.handle;

	useEffect(() => {
		var errorObject = {
			date: new Date(),
			message: error.message,
			stack: error.stack.slice(0, 500),
			pathname: location.pathname,
			resolved: false
		};

		if (!admin.error_log_save_pending && !errorLogged) {
			setErrorLogged(true);
			dispatch(actions_admin.logError(handle, errorObject));
		}
	}, [admin?.error_log_save_pending]);

	return (

		<Ibox className={className} title={'Something Went Wrong'} style={style}>
			<h2 className="text-center" style={{ fontSize: '20px' }}>
				<i className="fas fa-exclamation-triangle"></i>
				&nbsp; Oops, something went wrong here.
			</h2>
			<div className="mt-5" style={{ height: '200px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
				<i className="fas fa-bug" style={{ fontSize: '100px', color: '#dddddd' }}></i>
				<button className="btn btn-dark btn-sm px-5 my-4" onClick={resetBoundary}>Try again</button>
			</div>
			{user.roles?.includes(ROLES.OWNER.id) &&
				<div onClick={() => { setIsOpen(!isOpen); }} style={{ cursor: 'pointer', marginBottom: '7px', paddingBottom: '1px' }}>
					{isOpen
						? <span className="float-right px-1" style={{ fontSize: '16px' }}><i className="fas fa-angle-up"></i></span>
						: <span className="float-right px-1" style={{ fontSize: '16px' }}><i className="fas fa-angle-down"></i></span>
					}
				</div>
			}
			<h2 className="text-center"><small>Developers have been notified<br />and are scrambling to resolve the issue!</small></h2>
			{isOpen &&
				<small><pre style={{ color: "red" }}>{error.message + "\n\n" + 'Pathname: ' + location.pathname + "\n\n" + error.stack}</pre></small>
			}
		</Ibox>
	);
}
